import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const privacyPage: React.FC<{}> = () => (
	<PrimaryLayout>
		<SEO title="Privacy policy" />
		<Container py="40px">
			<h1> Privacy Policy </h1>
			<p> We respect your privacy! </p>
			<p> Any and all the information collected on this site will be kept strictly confidential and will not be sold, reused, rented, disclosed, or loaned! Any information you provide will be held with the utmost care and will not be used in ways that you have not consented to. If you have any questions, please feel free to contact us.
			Securing Your Data </p>

			<p> We have appropriate physical, electronic and procedural security safeguards to protect and secure the information we collect. </p>
			<h2>Your Consent </h2>
			<p>By using our Web site, you consent to our use of customer information as described in this online privacy policy. We may change or add to this privacy policy so we encourage you to review it periodically </p>
			<h2>SSecure Sockets Layer (SSL) </h2>
			<p>Our Web site uses 128-bit Secure Sockets Layer (SSL) encryption to protect your personal information, including your credit card number, before it travels over the Internet. SSL technology is the industry standard for secure online transactions. Because we use SSL, placing an order online at our Web site is just as safe as giving your credit card number over the phone.When you proceed to our online checkout, either a solid key or a locked padlock icon will appear in the lower right of your browser window. This icon insures that your transaction session is secured through SSL.</p>
			<h2>Our Privacy Policy </h2>

			<p>We have appropriate physical, electronic and procedural security safeguards to protect and secure the information we collect.</p>		
		</Container>		
	</PrimaryLayout>
);

export default privacyPage;
